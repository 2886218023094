import React from 'react';

import Base from '../components/Base';
import Splash from '../components/Splash';
import AboutSection from '../components/sections/AboutSection';
import CaseStudySection from '../components/sections/CaseStudySection';
import SEO from '../components/SEO';
import CTASection from '../components/sections/CTASection';
import DualPhonesFB from '../components/queriedImages/DualPhonesFB';
import DualPhonesPJ from '../components/queriedImages/DualPhonesPJ';
import CultureBiosciencesCasePreviewImage from '../components/queriedImages/CultureBiosciencesCasePreviewImage';
import Strings from '../constants/strings';

const IndexPage = () => (
  <Base>
    <SEO />
    <Splash />
    <AboutSection />
    <CaseStudySection
      dark
      title={Strings.CULTURE_BIOSCIENCES_TITLE}
      subtitle={Strings.CULTURE_BIOSCIENCES_SUBTITLE}
      description={Strings.CULTURE_BIOSCIENCES_DESCRIPTION}
      to="/work/culture-biosciences-case-study"
      ImageComponent={() => (
        <CultureBiosciencesCasePreviewImage
          objFit="cover"
          objPosition="0% 0%"
          style={{
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
            left: '50%',
            bottom: 0,
            right: 0,
          }}
          imgStyle={{ left: '4%', width: '588px', height: '86%', top: '10%' }}
        />
      )}
    />
    <CaseStudySection
      dark
      title={Strings.FACEBOOK_TITLE}
      subtitle={Strings.FACEBOOK_SUBTITLE}
      description={Strings.FACEBOOK_DESCRIPTION}
      to="/work/facebook-case-study"
      ImageComponent={() => (
        <DualPhonesFB
          objFit="cover"
          objPosition="0% 0%"
          style={{
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
            left: '50%',
            bottom: 0,
            right: 0,
          }}
          imgStyle={{ left: '4%', width: '500px' }}
        />
      )}
    />
    <CaseStudySection
      dark
      title={Strings.PEACHJAR_TITLE}
      subtitle={Strings.PEACHJAR_SUBTITLE}
      description={Strings.PEACHJAR_DESCRIPTION}
      to="/work/peachjar-case-study"
      ImageComponent={() => (
        <DualPhonesPJ
          objFit="cover"
          objPosition="0% 0%"
          style={{
            overflow: 'hidden',
            position: 'absolute',
            top: 0,
            left: '50%',
            bottom: 0,
            right: 0,
          }}
          imgStyle={{ left: '4%', width: '500px' }}
        />
      )}
    />
    <CTASection colorful />
  </Base>
);

export default IndexPage;
